import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { sendInvitationByEmail } from "actions/admin/customerAction.js";
import { toast } from "react-toastify";

export const useInvitationCustomer = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, errors, control, setValue } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const [hiddenDialog, setHiddenDialog] = useState(false);

  const onSubmit = async (data, e) => {
    setIsLoading(true);

    const response = await dispatch(sendInvitationByEmail(data));

    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      setIsLoading(false);
      handleCloseDialog(false);
    } else {
      setIsLoading(false);
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
    setIsLoading(false);
  };

  const handleCloseDialog = (value = false) => {
    setValue("email", "");
    setHiddenDialog(value);
  };

  const actionsInv = {
    handleCloseDialog,
  };

  const formElementInv = {
    isLoading,
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    onSubmit,
  };

  const stateInv = {
    hiddenDialog,
  };

  return {
    actionsInv,
    formElementInv,
    stateInv,
  };
};
