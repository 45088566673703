import API from "config/config-api";
import {
  SEARCH_CUSTOMER_CODE,
  LIST_CUSTOMER,
  SELECTED_ROWS_CLI,
  ACTIVE_CUSTOMER_CODE,
  LOADING_CUSTOMER,
} from "types/admin/customerType";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getLoading = (value) => async (dispatch, getState) => {
  const payload = {
    data: null,
    loading: value,
  };
  dispatch(requestSuccess(LOADING_CUSTOMER, payload));
};

export const searchCustomerByRUT = (rut) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.customer.search(rut));
    const { status, message, data } = response.data;
    return { status, message, data };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const searchCustomer = (body) => async (dispatch, getState) => {
  try {
    const dataSend = {
      ...body,
      value: body.value.toLowerCase(),
    };

    let response = await API.post(endPoints.customer.typeSearch, dataSend);
    const { status, message, data, created } = response.data;
    return { status, message, data, created };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const habilitarNuevoCliente = (status) => (dispatch, getState) => {
  const payload = {
    status,
  };
  dispatch(requestSuccess(SEARCH_CUSTOMER_CODE, payload));
};

export const getListCustomers = (body) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.customer.list);
    const { status, message, data } = response.data;
    const payload = {
      listAll: !status ? [] : data,
    };
    dispatch(requestSuccess(LIST_CUSTOMER, payload));
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const getSelected = (rows) => (dispatch, getState) => {
  const payload = {
    data: rows !== null ? rows : null,
  };
  dispatch(requestSuccess(SELECTED_ROWS_CLI, payload));
};

export const deleteCustomer = (id) => async (dispatch, getState) => {
  try {
    const { listCustomers } = getState().customers;

    let response = await API.delete(endPoints.customer.delete(id));
    const { status, message } = response.data;
    if (status) {
      const payload = {
        listAll: listCustomers.filter((customer) => customer.id !== id),
        data: null,
      };

      dispatch(requestSuccess(ACTIVE_CUSTOMER_CODE, payload));
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const addCustomer = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.customer.add, body);
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const editCustomer =
  (body, customerId) => async (dispatch, getState) => {
    try {
      let response = await API.put(endPoints.customer.edit(customerId), body);
      const { status, message } = response.data;

      return { status, message };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const searchCustomerById =
  (customerId) => async (dispatch, getState) => {
    try {
      let response = await API.get(endPoints.customer.getOne(customerId));

      const { status, message, data } = response.data;

      return { status, message, data };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const changeStatus = (id) => async (dispatch, getState) => {
  try {
    const { listCustomers, customerData } = getState().customers;

    const body = {
      type: "status",
      option: customerData?.active ? "desactivado" : "activado",
    };

    let response = await API.put(endPoints.customer.edit(id), body);
    const { status, message } = response.data;
    if (response.status) {
      const listAll = listCustomers.map((customer) => {
        if (customer.id === id) {
          return { ...customer, active: !customerData.active };
        }
        return customer;
      });

      const payload = {
        data: {
          ...customerData,
          active: !customerData.active,
        },
        listAll,
      };

      dispatch(requestSuccess(ACTIVE_CUSTOMER_CODE, payload));
    }

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const deleteAccountNumberByCustomer =
  (accuntNumberId) => async (dispatch, getState) => {
    try {
      let response = await API.delete(
        endPoints.accountNumber.delete(accuntNumberId)
      );
      const { status, message } = response.data;
      return { status, message };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const serarchAccountNumberByCustomer =
  (accuntNumberId) => async (dispatch, getState) => {
    try {
      let response = await API.get(
        endPoints.accountNumber.getOne(accuntNumberId)
      );
      const { status, message } = response.data;
      return { status, message };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const updateStatusAccountByCustomer =
  (accuntNumberId, body) => async (dispatch, getState) => {
    try {
      let response = await API.put(
        endPoints.accountNumber.edit(accuntNumberId),
        body
      );
      const { status, message } = response.data;
      return { status, message };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const sendInvitationByRUT = (body) => async (dispatch, getState) => {
  try {
    const dataSend = {
      rut: body?.rut,
      email: body?.email,
    };
    let response = await API.post(endPoints.customer.invitation, dataSend);
    const { status, message } = response.data;
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const sendInvitationByEmail = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.customer.invitationByEmail, body);
    const { status, message } = response.data;
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};
