import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import Progress from "../Layouts/Progress";

const DialogInvitation = ({ state, actions, formElement }) => {
  const { handleCloseDialog } = actions;

  const { isLoading, register, handleSubmit, errors, onSubmit } = formElement;

  const { hiddenDialog } = state;

  return (
    <Modal show={hiddenDialog} onHide={handleCloseDialog} centered size="md">
      <Modal.Header closeButton>
        <h6 className="mb-0">Enviar invitación al cliente</h6>
      </Modal.Header>
      <Form id="frmFactura" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <p className="mx-5 text-center">
                Introduzca el correo electrónico del cliente para enviarle la
                invitación al portal web
              </p>
            </Col>
          </Row>

          {/**<Row>
              <Col sm={6}>
                <Form.Group controlId="rut">
                  <Form.Label>RUT</Form.Label>
                  <Controller
                    autoComplete="off"
                    render={(props) => (
                      <MaskedInput
                        mask={RutTextMask}
                        className="form-control"
                        id="rut"
                        name="rut"
                        value={props.value}
                        maxLength={12}
                        //defaultValue={ordenInfo.rut}
                        onChange={(value) => {
                          props.onChange(value);
                        }}
                        onBlur={(event) => handleBlurRut(event.target.value)}
                        onKeyDown={(event) => onKeyDownHandlerInput(event)}
                        onKeyPress={(event) => validateOnlyRUT(event)}
                      />
                    )}
                    control={control}
                    name="rut"
                    rules={{ required: { value: true, message: "Requerido" } }}
                  />
                  {errors.rut && (
                    <Form.Text className="text-danger">
                      {errors?.rut?.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row> */}
          <Row>
            <Col sm={12}>
              <Form.Group controlId="rut">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  ref={register({
                    required: { value: true, message: "Requerido" },
                    //eslint-disable-next-line
                    pattern: {
                      value:
                        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, //eslint-disable-line
                      message: "Email inválido",
                    },
                  })}
                  maxLength={100}
                  //readOnly={disabledInput}
                />
                {errors.email && (
                  <Form.Text className="text-danger">
                    {errors?.email?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          {isLoading && (
            <Row>
              <Col sm={12}>
                <div className="pb-4 text-center">
                  <Progress />
                  <p className="font-italic fs-12 ">
                    Enviando la invitación al portal web, espere unos minutos...
                  </p>
                </div>
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="pl-5 pr-5"
            variant="outline-warning"
            onClick={() => handleCloseDialog(false)}
            disabled={isLoading}
          >
            Cerrar
          </Button>
          <Button
            type="submit"
            className="pl-5 pr-5"
            variant="warning"
            disabled={isLoading}
          >
            {isLoading ? "Enviando..." : "Enviar"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default DialogInvitation;
